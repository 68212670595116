import React from "react";

const Title_component2 = ({ title }) => {
  return (
    <>
      <div className="tiltle_box">
        <h3>{title}</h3>
      </div>
    </>
  );
};

export default Title_component2;
