import React from "react";
import { Link } from "react-router-dom";
import Title_component from "../component/Title_component";
import Title_component2 from "../component/Title_component2";

const AboutUs = () => {
  return (
    <>
      {/* <Title_component title="More about Savira Life" /> */}
      <div className="about_header_cont">
        <img src="../assets/images/TeaState_banner2.png" alt="img" />
        <h4>More About Savira Life</h4>
      </div>
      <div className="background_app_box_1">
        <div className="about_us_container ">
          <div className="about_us_image_box">
            <img src="../assets/images/AboutUs1.webp" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_1">
              At Savira Life, we believe wellness is more than a lifestyle—it’s
              a journey that begins with every sip. Our mission is to redefine
              tea, transforming it from a simple beverage into an experience of
              self-care, peace, and vitality. Specializing in premium
              handcrafted blends, we source the finest organic tea leaves to
              craft infusions that nourish the mind, body, and soul. Every cup
              of our tea embodies a commitment to ethical sourcing and wellness.
            </p>
            <p className="about_colour_1">
              Blending timeless herbal traditions with modern wellness, we offer
              a thoughtfully curated selection of teas, including{" "}
              <b>Lavender</b>,<b> Chamomile</b>, <b>Rooibos</b>, <b>Blue Pea</b>
              , and <b>Hibiscus</b>. Rich in antioxidants and renowned for their
              calming properties, our teas are designed to elevate your daily
              routine. From relaxation to revitalization, each blend delivers
              vibrant flavors and soothing effects, making it the perfect
              companion for every moment of your day.
            </p>
            <p className="about_colour_1">
              At Savira Life, tea is more than a drink—it’s a ritual of
              nourishment and tranquility. Whether you’re unwinding after a long
              day or seeking a moment of reflection, our teas provide a
              harmonious balance of health and flavor. By combining the essence
              of herbal remedies with modern approaches to self-care, we invite
              you to connect with your inner self, one sip at a time.
            </p>
          </div>
        </div>
      </div>
      <div className="background_app_box">
        <Title_component title="Blue Pea Tea" />
        <div className="about_us_container ">
          <div className="about_us_image_box show_img_mob">
            <img src="../assets/images/Bluepea_Tea.png" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_2">
              Step into a world of vibrant wellness with the mesmerizing hues of
              Blue Pea tea. This caffeine-free infusion not only captivates the
              senses with its enchanting blue color but also offers a holistic
              approach to health and well-being. Packed with antioxidants, Blue
              Pea tea supports brain health, promotes detoxification, and
              encourages relaxation. Its eco-friendly, vegan, and dairy-free
              formulation makes it a conscious choice for those seeking
              sustainable and nourishing options.
            </p>
            <p className="about_colour_2">
              Blue Pea tea stands out as the drink of choice for those aspiring
              to maintain youth and beauty. With its calming yet stimulating
              properties, it balances the mind and body, enhancing mental
              clarity while soothing the senses. Free from any unpleasant odors,
              this delicate tea blend delivers a pure, refreshing taste. Whether
              you’re unwinding after a long day or seeking an invigorating
              start, Blue Pea tea fits seamlessly into any lifestyle.
            </p>
            <p className="about_colour_2">
              Embrace the benefits of Blue Pea tea and experience a harmonious
              blend of wellness and flavor. Its vibrant blue color is a visual
              treat, perfectly complementing its health-boosting qualities. By
              choosing Blue Pea tea, you’re not just opting for a drink—you’re
              stepping into a rejuvenating ritual that supports a healthy,
              sustainable lifestyle.
            </p>
          </div>
          <div className="about_us_image_box show_img_lap">
            <img src="../assets/images/Bluepea_Tea.png" alt="img" />
          </div>
        </div>
      </div>
      <div className="background_app_box_1">
        <Title_component2 title="Hibiscus Tea" />
        <div className="about_us_container ">
          <div className="about_us_image_box">
            <img src="../assets/images/Habicus_Tea.png" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_1">
              Hibiscus tea, with its unique blend of tartness and fruity
              sweetness, is a delightful natural remedy cherished for its
              remarkable benefits. Rich in Vitamin C, it offers a refreshing
              boost to your health while promoting hydration and vitality. This
              vibrant herbal infusion not only tantalizes your taste buds but
              also sets the stage for glowing, radiant skin, making it a
              favorite among health enthusiasts.
            </p>
            <p className="about_colour_1">
              At the heart of hibiscus tea's skin-enhancing magic lies its
              ability to boost collagen production. Collagen, an essential
              protein for skin elasticity and strength, thrives when fueled by
              Vitamin C. This tea's hydrating properties further nourish the
              skin, combating dryness and restoring a youthful glow. Each sip
              becomes a rejuvenating experience, enhancing your skin's natural
              radiance while supporting your overall well-being.
            </p>
            <p className="about_colour_1">
              Beyond its skincare perks, hibiscus tea delivers a host of
              additional health benefits. Packed with antioxidants, it helps
              combat oxidative stress, promoting a sense of vitality and inner
              balance. Whether enjoyed hot or cold, hibiscus tea's vibrant
              flavor and incredible benefits make it a perfect choice for anyone
              looking to feel refreshed, energized, and radiant from the inside
              out.
            </p>
          </div>
        </div>
      </div>
      <div className="background_app_box">
        <Title_component title="Chamomile Tea" />
        <div className="about_us_container ">
          <div className="about_us_image_box show_img_mob">
            <img src="../assets/images/Chamomile_Tea.png" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_2">
              Unwind and discover serenity with Chamomile tea, the perfect
              companion for peaceful evenings. Renowned for its calming
              properties, this gentle infusion helps you relax, offering a
              moment of tranquility amidst the chaos of daily life. Its soothing
              nature makes it an ideal choice to ease stress and prepare your
              body and mind for restful sleep.
            </p>
            <p className="about_colour_2">
              After a long and stressful day, Chamomile tea can be your go-to
              remedy. Its natural compounds are known to alleviate tension,
              creating a sense of calm that gently melts away the day’s worries.
              Beyond stress relief, this delightful beverage ensures a deep and
              rejuvenating sleep, helping you wake up refreshed and energized.
            </p>
            <p className="about_colour_2">
              Whether you're looking for a way to de-stress or a solution to
              restless nights, Chamomile tea is your ultimate evening ritual.
              Let its soothing aroma and benefits prepare you for a brighter
              tomorrow, as you wake up ready to embrace the day with renewed
              vigor and positivity.
            </p>
          </div>
          <div className="about_us_image_box show_img_lap">
            <img src="../assets/images/Chamomile_Tea.png" alt="img" />
          </div>
        </div>
      </div>
      <div className="background_app_box_1">
        <Title_component2 title="Lavender Tea" />
        <div className="about_us_container ">
          <div className="about_us_image_box">
            <img src="../assets/images/Lavender_Tea.png" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_1">
              Lavender tea offers more than just a warm cup of relaxation—it’s a
              gateway to tranquility. Infused with the delicate essence of
              lavender flowers, this aromatic tea creates a soothing experience
              that calms the mind and uplifts the spirit. Its enchanting floral
              notes provide a peaceful retreat, perfect for unwinding after a
              long, hectic day. With every sip, you can feel the stress melt
              away as serenity takes over.
            </p>
            <p className="about_colour_1">
              In the hustle and bustle of daily life, finding moments of peace
              can feel elusive. Lavender tea becomes a sanctuary in your day,
              offering its natural calming properties to help you pause and
              breathe. The subtle floral aroma enhances mindfulness, creating a
              sense of balance and relaxation even during the busiest hours.
              Whether at work or home, this tea acts as your personal retreat,
              offering an uplifting boost to your mood.
            </p>
            <p className="about_colour_1">
              Lavender tea is not just about its soothing flavor—it’s about the
              wellness it brings to your mind and body. Known for its
              stress-relieving qualities, this tea helps promote mental clarity
              and emotional well-being. Each cup serves as a reminder to embrace
              moments of self-care and indulge in the simplicity of quiet joy.
              Experience the essence of peace and comfort in every brew, making
              Lavender tea an essential companion for your everyday life.
            </p>
          </div>
        </div>
      </div>
      <div className="background_app_box">
        <Title_component title="Rooibos Tea" />
        <div className="about_us_container ">
        <div className="about_us_image_box show_img_mob">
            <img src="../assets/images/Rooibos_Tea.png" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_2">
              Rooibos tea, an exceptional beverage originating from South
              Africa, captivates tea enthusiasts with its rich, nutty flavor and
              health benefits. This herbal infusion, made from the Aspalathus
              linearis plant, is a flavorful alternative to traditional teas.
              Its deep, earthy notes and natural sweetness make it a delightful
              companion for any meal, appealing to those seeking both taste and
              wellness in a single cup.
            </p>
            <p className="about_colour_2">
              What sets Rooibos tea apart is its powerhouse of antioxidants and
              its caffeine-free composition. These antioxidants play a crucial
              role in combating free radicals, supporting overall health. Its
              digestive properties aid in easing discomfort and improving gut
              health, while its heart-friendly compounds promote cardiovascular
              well-being. Moreover, its immunity-boosting qualities make it a
              year-round favorite, offering a shield against common illnesses
              without the jittery effects of caffeine.
            </p>
            <p className="about_colour_2">
              Whether you're pairing it with a hearty breakfast, a light lunch,
              or a sumptuous dinner, Rooibos tea fits seamlessly into every
              dining experience. Its natural goodness and soothing qualities
              make it an ideal choice for those seeking a nourishing and
              flavorful beverage. By incorporating Rooibos tea into your daily
              routine, you embrace a tradition rooted in South Africa's rich
              heritage while enhancing your overall health.
            </p>
          </div>
          <div className="about_us_image_box show_img_lap">
            <img src="../assets/images/Rooibos_Tea.png" alt="img" />
          </div>
        </div>
      </div>
      <div className="background_app_box_1">
        <Title_component2 title="Why Choose Savira Life?" />
        <div className="about_us_container ">
          <div className="about_us_image_box">
            <img src="../assets/images/AboutUs2.webp" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_1">
              At Savira Life, we believe tea is more than just a beverage—it's
              a connection to nature and a gateway to wellness. Our teas are
              lovingly grown in the open air, where each leaf is nurtured with
              respect for the environment. By partnering directly with farmers,
              we ensure the purity and freshness of every harvest, preserving
              the authentic essence of tea in every cup.
            </p>
            <p className="about_colour_1">
              Our thoughtfully crafted blends are designed to invigorate both
              body and mind. From the calming notes of lavender tea to the
              vibrant flavors of herbal hibiscus, each variety is a unique
              infusion of nature’s finest. Whether you seek relaxation or an
              energizing boost, our teas promise a wholesome, flavorful
              experience that supports your well-being.
            </p>
            <p className="about_colour_1">
              Choose Savira Life for tea that celebrates nature,
              sustainability, and authenticity. With every sip, immerse yourself
              in the goodness of handpicked leaves and petals, lovingly crafted
              to nourish your soul. Savira Life is your trusted partner in
              wellness, one cup at a time.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
