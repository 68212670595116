import React from "react";

const Title_component = ({ title }) => {
  return (
    <>
      <div className="tiltle_box">
        <h2>{title}</h2>
      </div>
    </>
  );
};

export default Title_component;
