import React from "react";

const ReturnRefund = () => {
  return (
    <>
      <section className="container return">
        <h3 className="">Return & Refund</h3>
        <hr></hr>
        <ul>
          <li className="" style={{ textAlign: "justify" }}>
            <b>1. </b> We have a "no questions asked return at delivery and
            refund policy" which entitles all our members to return the product
            at the time of delivery if due to some reason they are not satisfied
            with the products delivered. We will take the returned product back
            with us and issue a credit note for the value of the returned
            products which will be credited to your account on Savira Foods(<b>Sayva Foods Private Limited</b>). This
            can be used to pay for your subsequent shopping bills. If you have
            opted for cash on delivery, Pay only for the satisfied products.
          </li>
          <li className="mt-lg-3" style={{ textAlign: "justify" }}>
            <b>2. </b> Acceptance of returns & refund post-delivery is subject
            to a satisfactory inspection by our customer service team. Please
            get in touch with us via self-service on the Savira Foods(<b>Sayva Foods Private Limited</b>) app or
            contact our customer care numbers for any such requests.
          </li>
          <li className="mt-lg-3" style={{ textAlign: "justify" }}>
            <b>3. Note: </b> As a Covid-19 safety and hygiene measure, we will
            not pick up any goods post-delivery. We request all our members to
            thoroughly check the products at the time of delivery.
          </li>
        </ul>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default ReturnRefund;
