import React from "react";
import {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const HomeSlider = () => {
  const { VegBannerTotal } = useSelector((state) => state.HomeBanner);
  const { isAuth, name, loginData, showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );

  return (
    <>
      <div className="bannerslidercontainer">
        <div className="bannerslidercontainer1">
          <section className="home-section-2 home-section-bg pt-0 overflow-hidden">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <Swiper
                    grabCursor={true}
                    navigation={false}
                    pagination={true}
                    mousewheel={false}
                    keyboard={true}
                    draggable={true}
                    effect={"fade"}
                    modules={[
                      EffectFade,
                      Navigation,
                      Pagination,
                      Mousewheel,
                      Keyboard,
                      Autoplay,
                    ]}
                    loop={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                      360: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      460: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                      },
                      720: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      1080: {
                        slidesPerView: 1,
                        spaceBetween: 7,
                      },
                    }}
                    className="homeSlider"
                  >
                    {VegBannerTotal &&
                      VegBannerTotal.map((banner, index) => (
                        <SwiperSlide key={index}>
                          <div className="slider-animate">
                            <div className="home-contain">
                              {/* <Link to={`/home-slider/${banner.category}`}> */}
                                <img
                                  src="../assets/images/TeaState_Banner.webp"
                                  // src={banner.DeskImg}
                                  className="img-fluid bg-img blur-up lazyload"
                                  alt="img"
                                  style={{
                                    cursor: "pointer",
                                    // borderRadius: "8px",
                                  }}
                                />
                              {/* </Link> */}
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                {/* <marquee scrolldelay="100">
              <h6
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#0e947a",
                  marginTop: "5px",
                }}
              >
                {massageText}
              </h6>
            </marquee> */}
              </div>
            </div>
          </section>
        </div>
      </div>
      <div style={{ marginBottom: "10px" }}></div>
    </>
  );
};

export default HomeSlider;
