import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BsHouseDoor } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  LoginOtp,
  loginAuthentication,
  pageStatusChange,
} from "../../redux/athentication/Athentication";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import {
  getCartTotal,
  removefromCart,
  setSearchProducts,
} from "../../redux/cart/CartSlice";
import LoginScreenMobile from "./LoginScreenMobile";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSerch] = useState("");
  const [subCatSerchList, setSubCatSerchList] = useState("");
  const [catSerchList, setCatSerchList] = useState([]);
  const [prodSerchList, setProdSerchList] = useState([]);
  const [brandprodSerchList, setBrandProdSerchList] = useState("");
  const [showResults, setShowResults] = useState(false);
  const ref = useRef();

  const [fetchAllProducts, setFetchAllProducts] = useState([]);
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { categoryTotal } = useSelector((state) => state.Category);
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const { isAuth, name, loginData, showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );

  const [number, setNumber] = useState("");
  const [errormassage, setErrormassage] = useState("");

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8018582135") {
        OTP = "111444";
      }
      dispatch(LoginOtp({ number, OTP }));
      const formdata = {
        number: number,
        OTP: OTP,
      };
      dispatch(loginAuthentication(formdata));
    } else {
      setErrormassage(" Please enter valid number");
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(pageStatusChange(0));
  }, [CartItems, dispatch]);
  const removefromcarts = (cartItem) => {
    dispatch(removefromCart(cartItem));
  };

  const searchchange = (e) => {
    const result = e.target.value;
    setSerch(result);

    if (result.length >= 2) {
      const filtersubcatbyserch = subCategoryTotal
        .filter((subcat) =>
          subcat.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 2);
      const filtercatdbyserch = categoryTotal
        .filter((cat) => cat.name.toLowerCase().match(result.toLowerCase()))
        .slice(0, 2);
      const filterProductbyserch = ProductTotal.filter((product) =>
        product.ItemName.toLowerCase().match(result.toLowerCase())
      ).slice(0, 2);

      // const filterBrandbyserch = groceresBrand
      //   .filter((brand) => brand.name.toLowerCase().match(result.toLowerCase()))
      //   .slice(0, 2);
      setSubCatSerchList(filtersubcatbyserch);
      setCatSerchList(filtercatdbyserch);
      setProdSerchList(filterProductbyserch);
      // setBrandProdSerchList(filterBrandbyserch);
      setShowResults(true);
    }
  };

  const searchsubmit = (e) => {
    e.preventDefault();
    navigate(`/product-search/${search}`);
    setShowResults(false);
  };

  const serchclick = () => {
    navigate(`/product-search/${search}`);
    setShowResults(false);
  };

  return (
    <>
      <header className="pb-md-3">
        {/* =============header top start===================== */}
        <div
          className="top-nav top-header sticky-header modileee_padding_none"
          style={{ boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">

                </div>

                <div className="navbar-top  mobile_nonnnne">
                  <div className="middle-box mobile mt-2 "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============header top end===================== */}
      </header>

      {/* desktop login page */}
      <section className="log-in-section background-image-2 section-b-space login_for_only_desktop">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="../assets/images/inner-page/log-in.png"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="log-in-box">
                <div className="login-image">
                  <img
                    src="../assets/images/inner-page/log-in.png"
                    height="120px"
                    width="190px"
                    alt="img"
                  />
                </div>
                <div className="log-in-title">
                  <h3>Welcome To Savira Foods</h3>
                  <h4>Log in or Sign up</h4>
                </div>
                <div className="input-box">
                  <form className="row g-4" onSubmit={onSubmitHandler}>
                    <div className="col-12">
                      <div className="textfieldcontainer">
                        <TextField
                          label="Enter Mobile Number"
                          // variant="outlined"
                          className="textfield"
                          type="tel"
                          inputMode="numeric"
                          id="text"
                          value={number}
                          minLength={10}
                          maxLength={10}
                          pattern="[6789][0-9]{9}"
                          onChange={(e) => handleChange(e)}
                          inputProps={{ style: { borderColor: "red" } }}
                        />
                        <p style={{ color: "tomato" }}>{errormassage}</p>
                      </div>
                    </div>
                    <div
                      className="col-12 bg-danger"
                      style={{ borderRadius: "10px" }}
                    >
                      <button
                        className="btn btn-animation w-100 justify-content-center"
                        type="submit"
                        style={{ height: "50px", fontSize: "16px" }}
                      >
                        Continue
                      </button>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <p style={{ textAlign: "center" }}>
                        By continuing, you agree to Savira Foods{" "}
                        <b style={{ color: "#0e947a" }}> Terms of Use </b>and{" "}
                        <b style={{ color: "#0e947a" }}>Privacy Policy</b>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* endd---- */}
      {/* this is for login  screen*/}

      <LoginScreenMobile />

      {/* endddd............... */}

      
    </>
  );
};

export default LoginScreen;
