import React from "react";
import Title_component from "../../component/Title_component";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hide_swipper_box_lap">
        <Title_component title="about Savira Life" />
        <div className="about_us_container">
          <div className="about_us_image_box">
            <img src="../assets/images/AboutUs.webp" alt="img" />
          </div>
          <div className="about_us_text_box">
            <p className="about_colour_2">
              Since we are more than just a business, we, at Savira Life,
              understand that wellbeing starts from the moment one takes a sip
              and therefore every sip counts. We focus on transforming tea to
              not remain as a drink but the beginning of self-care, peace and
              energy. We specialize in handcrafted organic blends, and we
              provide you with a selection of ethically sourced tea leaves that
              are teas designed to enhance your tea experience.
            </p>
            <p className="about_colour_2">
              Savira Life combines herbal tradition with modern wellness and
              therefore enables one’s mind, body and soul to connect through
              tea. Our <b>Lavender</b>, <b>Chamomile</b>, <b>Rooibos</b>,{" "}
              <b>Blue Pea</b> and <b>Hibiscus</b> teas have high antioxidant
              levels with calming effects and strong flavors which can be
              described as the perfect addition to your daily routine.
            </p>

            <div className="about_btn" onClick={() => navigate("about")}>
              <span>Read More</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
