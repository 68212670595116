import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidTimeFive } from "react-icons/bi";
import { FaShoppingCart, FaWallet } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader } from "reactstrap";
import { Button, Drawer, Radio, Space } from "antd";
import { FaChevronRight } from "react-icons/fa";
import moment from "moment/moment";
import { toast } from "react-toastify";
import {
  addressModelStatus,
  clientUpdate,
  currAdress,
  paymentTypeChange,
  updateAddress,
  walletTransitionPost,
  walletUpdate,
  walletUpdateAfterOrder,
  walletUseUpdate,
  addressmodel,
  editaddressModelStatus,
} from "../../redux/athentication/Athentication";
import AddAddress from "../../component/account/dashboard/address/AddAddress";
import {
  clearCart,
  netpayableUpdate,
  walletamountUpdate,
} from "../../redux/cart/CartSlice";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import CheckoutAddrs from "../../component/account/dashboard/CheckoutAddrs";
import RightSideCheckOut from "./RightSideCheckOut";
import RightSideCheckOut2 from "./RightSideCheckout2";
import { newOrder } from "../../redux/order/OrderSlice";
import BeatLoader from "react-spinners/BeatLoader";
import EditAddress from "../../component/account/dashboard/address/EditAddress";

const LeftSideCheckout = () => {
  const {
    address,
    currentAddress,
    addressmodel,
    loginData,
    clientWalletAmount,
    walletUse,
    paymentType,
    clientid,
    name,
    email,
    editaddressmodel,
  } = useSelector((state) => state.Athentication);
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    logisticsAmount,
    netPayable,
    cartTotalMrp,
    CouponDiscountAmount,
    cartTotalPrice,
    updateNetPayable,
    usedWalletAmount,
  } = useSelector((state) => state.Cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectaddress, setSelectAddress] = useState("");
  const [expectDeliveryTime, setExpectDeliveryTime] = useState("");
  const [walletstatus, setWalletStatus] = useState(Boolean(0));

  const [cartamountDetails, setCartamountDetails] = useState("");

  const [fname, setFname] = useState("");
  const [mobile, setMobile] = useState("");
  const [number, setNumber] = useState("");
  const [altmobile, setAltMobile] = useState(loginData.Mobile);
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [addres, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [errormassage, setErrormassage] = useState("");
  const [pinerrormassage, setPinErrormassage] = useState("");
  const [landmark, setLandmark] = useState("");
  const [areaDept, setAreaDept] = useState("");
  const [areaArray, setAreaArray] = useState([]);
  const [btnLoading, setbtnLoading] = useState(false);
  const [mainerrormassage, setMainErrormassage] = useState("");
  const [editableAddress, setEditableAddress] = useState(null);

  const editaddress = (e, data) => {
    const formData = {
      address: data,
    };
    setEditableAddress(formData);
    dispatch(editaddressModelStatus(true));
  };

  const addresPinarray = [
    { pinCode: "831001", id: 1 },
    { pinCode: "831002", id: 2 },
    { pinCode: "831003", id: 3 },
    { pinCode: "831004", id: 4 },
    { pinCode: "831005", id: 5 },
    { pinCode: "831006", id: 6 },
    { pinCode: "831007", id: 7 },
    { pinCode: "831008", id: 8 },
    { pinCode: "831009", id: 9 },
    { pinCode: "831010", id: 10 },
    { pinCode: "831011", id: 11 },
    { pinCode: "831012", id: 12 },
    { pinCode: "831013", id: 13 },
    { pinCode: "831014", id: 14 },
    { pinCode: "831015", id: 15 },
    { pinCode: "831016", id: 16 },
    { pinCode: "831017", id: 17 },
    { pinCode: "831018", id: 18 },
    { pinCode: "831019", id: 19 },
    { pinCode: "832109", id: 20 },
    { pinCode: "832110", id: 21 },
  ];
  const addresCityarray = [
    { pinCode: "831001", cityname: "Sakchi", id: 1 },
    { pinCode: "831001", cityname: "Bistupur", id: 2 },
    { pinCode: "831001", cityname: "Sonari", id: 3 },
    { pinCode: "831001", cityname: "Baradwari", id: 4 },
    { pinCode: "831001", cityname: "Dhatkidih", id: 5 },
    { pinCode: "831001", cityname: "XLRI Hyderabad", id: 6 },

    { pinCode: "831002", cityname: "Parsudihr", id: 7 },

    { pinCode: "831003", cityname: "Golmuri", id: 8 },
    { pinCode: "831003", cityname: "Cable town", id: 9 },
    { pinCode: "831003", cityname: "Sitaramdera", id: 10 },
    { pinCode: "831003", cityname: "Nildih", id: 11 },
    { pinCode: "831003", cityname: "Golmuri", id: 12 },

    { pinCode: "831004", cityname: "Telco", id: 13 },
    { pinCode: "831004", cityname: "Ghorabandha", id: 14 },
    { pinCode: "831004", cityname: "Kharangajhar", id: 15 },
    { pinCode: "831004", cityname: "Azad market", id: 16 },
    { pinCode: "831004", cityname: "Kalimati, sakchi", id: 17 },
    { pinCode: "831004", cityname: "Birsanagar", id: 18 },
    { pinCode: "831004", cityname: "chhota govindpur", id: 19 },

    { pinCode: "831005", cityname: "kadma", id: 20 },
    { pinCode: "831005", cityname: "Uliyan", id: 21 },

    { pinCode: "831006", cityname: "Jugsalai", id: 22 },

    { pinCode: "831007", cityname: "Burmamines", id: 23 },

    { pinCode: "831009", cityname: "Agrico", id: 24 },
    { pinCode: "831009", cityname: "Bhalubasa", id: 25 },
    { pinCode: "831009", cityname: "Sidhgora", id: 26 },
    { pinCode: "831009", cityname: "Bhuiyadih", id: 27 },

    { pinCode: "831011", cityname: "Adarsh Nagar", id: 28 },
    { pinCode: "831011", cityname: "sonari", id: 29 },

    { pinCode: "831012", cityname: "Mango", id: 30 },
    { pinCode: "831012", cityname: "Dimna", id: 31 },
    { pinCode: "831012", cityname: "sahara city, Mango", id: 32 },

    { pinCode: "831013", cityname: "Adityapur-1", id: 33 },

    { pinCode: "831015", cityname: "Chhota Govindpur", id: 34 },

    { pinCode: "831017", cityname: "Vijaya garden, Baridih", id: 35 },
    { pinCode: "831017", cityname: "Baridih colony", id: 36 },
    { pinCode: "831017", cityname: "Birsanagar", id: 37 },
    { pinCode: "831017", cityname: "Baridih", id: 38 },

    { pinCode: "831018", cityname: "MGM Medical college", id: 39 },

    { pinCode: "831019", cityname: "Birsanagar", id: 40 },

    { pinCode: "832109", cityname: "Adityapur", id: 41 },

    { pinCode: "832110", cityname: "Azadnagar, mango", id: 42 },
  ];
  useEffect(() => {
    const filterarreabypin = addresCityarray.filter(
      (pin) => pin.pinCode === pincodecon
    );

    setAreaArray(filterarreabypin);
  }, [pincodecon]);

  useEffect(() => {
    let cartTotalAmountN = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN =
        Number(cartTotalAmountN) + Number(CartItems[i].TotalAmount);
      cartTotalMrpN = Number(cartTotalMrpN) + Number(CartItems[i].TotalMrp);
      cartTotalPriceN =
        Number(cartTotalPriceN) + Number(CartItems[i].TotalPrice);
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    netPayableN = Number(cartTotalAmountN) + Number(logisticsAmountN);

    const cartAmountsForm = {
      cartTotalAmount: Number(cartTotalAmountN),
      netPayable: Number(netPayableN),
      cartTotalMrp: Number(cartTotalMrpN),
      cartTotalPrice: Number(cartTotalPriceN),
      logisticsAmount: Number(logisticsAmountN),
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    setExpectDeliveryTime(date);
  }, []);

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
    if (numbers.length === 10) {
      setMobile(numbers);
      setErrormassage("");
    } else {
      setMobile("");
      setErrormassage("please enter currect number");
    }
  };

  const selectPincode = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPinCodecon(text);
    // setSupercatid(value);
    // setSupercat(text);
  };
  const selectcity = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCity(text);
  };

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
    // const { value } = evt.target;
    // const index = evt.nativeEvent.target.selectedIndex;
    // setSelectAddress(value);
  };

  const statuschange = async (evt) => {
    const changedStatus = !walletstatus;
    const walletamount = loginData.WalletBalance;
    setWalletStatus(changedStatus);
    if (changedStatus === true) {
      if (cartamountDetails.netPayable > walletamount) {
        dispatch(netpayableUpdate(cartamountDetails.netPayable - walletamount));
        dispatch(walletamountUpdate(walletamount));
        dispatch(walletUseUpdate(true));
      } else {
        dispatch(netpayableUpdate(0));
        dispatch(walletamountUpdate(cartamountDetails.netPayable));
        dispatch(walletUseUpdate(true));
      }
    } else {
      dispatch(netpayableUpdate(cartamountDetails.netPayable));
      dispatch(walletamountUpdate(0));
      dispatch(walletUseUpdate(false));
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setMainErrormassage("");
    let addresss = [...address];
    if (mobile !== "" && pincodecon !== "" && city !== "") {
      try {
        const formData = {
          AName: fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase(),
          Number: mobile,
          Mobile: altmobile,
          Pincode: pincodecon,
          State: state,
          City: city,
          HNo: houseNo,
          StreetDet: street,
          AreaDet: areaDept,
          LandMark: landmark,
          Address: addres,
          Type: addressType,
          // AName: area,
          ClientId: clientid,
        };

        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        const url = `${Baseurl}/api/v1/client/address`;
        const addAddress = await axios.put(url, formData, config);

        if (addAddress.data.success) {
          const lastaddress = addAddress.data.client;

          const currentAddress = [...lastaddress.Addresses];

          // dispatch(currAdress(currentAddress.Addresses[0]));
          dispatch(updateAddress(currentAddress));
          // dispatch(addressModelStatus(false));
        }
      } catch (error) {}
    } else {
      setMainErrormassage("please enter currect values");
    }
  };
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("bottom");
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };

  const cashOnDeliveryHandleSubmit = async (e) => {
    if (!btnLoading) {
      setbtnLoading(true);
      const addressString = `${
        currentAddress.HNo +
        "," +
        currentAddress.StreetDet +
        "," +
        currentAddress.AName +
        "," +
        currentAddress.LandMark +
        "," +
        currentAddress.City +
        "," +
        currentAddress.State +
        "," +
        currentAddress.Type +
        "," +
        currentAddress.Pincode
      }`;

      let grandasTotal = 0;
      if (!walletUse) {
        grandasTotal = cartamountDetails.netPayable;
      } else {
        grandasTotal = updateNetPayable;
      }

      let CartItemForNow = CartItems.map((item) => ({
        ...item,
        Brand: item.Brand || "Brand",
      }));

      var date = new Date();
      date.setDate(date.getDate() + 1);
      // console.log(cartamountDetails, "totalAmount");
      if (currentAddress !== "" && cartamountDetails.cartTotalAmount > 0) {
        const orderData = {
          ClientId: clientid,
          ClientName: name,
          TotalAmount: Number(cartamountDetails.cartTotalAmount),
          Email: email,
          DeliveryCharge: logisticsAmount,
          wallet: usedWalletAmount,
          GrandTotal:
            Number(cartamountDetails.cartTotalAmount) -
            Number(usedWalletAmount),
          Address: addressString,
          AreaName: currentAddress.StreetDet,
          Mobile: loginData.Mobile,
          PaymentStatus: "COD",
          PaymentMode: "COD",
          ExpectedDelDate: date,
          ProductCount: CartItemForNow.length,
          Saving:
            cartamountDetails.cartTotalMrp +
            CouponDiscountAmount -
            cartamountDetails.cartTotalPrice,
          Cashback: "0",
          CurrentStatus: "Not Paid",
          TxnId: "NA",
          couponDetails: {},
          OrderProducts: CartItemForNow,
        };
        // console.log(orderData, "orderData");
        const order = await dispatch(newOrder(orderData));
        console.log(order.payload, "order.payload");

        if (!order) {
          console.log("not able to book your order");
        } else {
          if (order.payload.success) {
            dispatch(clearCart());
            const orderId = order.payload.order;
            const userUpdateForm = {
              claintid: loginData._id,
              WalletBalance: loginData.WalletBalance - usedWalletAmount,
            };

            const client = await dispatch(clientUpdate(userUpdateForm));

            dispatch(
              walletUpdateAfterOrder(loginData.WalletBalance - usedWalletAmount)
            );

            const walletForm = {
              ClientId: loginData._id,
              previousAmount: loginData.WalletBalance,
              availableAmount: loginData.WalletBalance - usedWalletAmount,
              transactionAmount: usedWalletAmount * -1,
              orderId: orderId._id,
              Type: "Wallet debit on New Order",
              ClientName: loginData.Name,
              Mobile: loginData.Mobile,
            };

            dispatch(walletTransitionPost(walletForm));

            const sendOptthowwp = async () => {
              const mobile = loginData.Mobile;
              let data = JSON.stringify({
                countryCode: "+91",
                phoneNumber: mobile,
                callbackData: "some text here",
                type: "Template",
                template: {
                  name: "order_placed_prepaid",
                  languageCode: "en",
                  bodyValues: [name],
                },
              });

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.interakt.ai/v1/public/message/",
                headers: {
                  Authorization:
                    "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {})
                .catch((error) => {
                  console.log(error);
                });
            };
            sendOptthowwp();

            toast.success("ORDER PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 500,
            });
            navigate("/order-success");
          } else {
            toast.error("ORDER NOT PLACED SUCCESSFULL", {
              position: "top-center",
              autoClose: 2000,
            });
            setbtnLoading(false);
          }
        }
      } else if (cartamountDetails.cartTotalAmount === 0) {
        toast.error("Please Check The Cart Products", {
          position: "top-center",
          autoClose: 2000,
        });
      } else if (currentAddress === "") {
        toast.error("Please Add Address", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    }
  };

  const payTmPayHandleSubmit = async (e) => {
    toast.error("Please Select COD", {
      position: "top-center",
      autoClose: 2000,
    });
  };

  return (
    <>
      <div className="col-lg-8 p-2">
        <div className="left-sidebar-checkout">
          <div className="checkout-detail-box">
            <ul>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdLocationOn style={{ fontSize: "20px", color: "" }} />
                </div>
                <div className="checkout-box">
                  <div className="checkout-detail">
                    {address.length > 0 && currentAddress !== "" ? (
                      <>
                        <CheckoutAddrs />
                      </>
                    ) : (
                      <>
                        <div
                        className="addmobversioncontainer"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => dispatch(addressModelStatus(true))}
                        >
                          <h4
                           className="addmobversioncontainer"
                            style={{
                              fontSize: "20px",
                              color: "#000",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                              cursor: "pointer",
                              paddingTop: "10px",
                            }}
                          >
                            Please add Your address
                          </h4>
                        </div>

                        <div className="modal-body">
                          <div className="row g-4">
                            <Modal
                              size="md"
                              isOpen={addressmodel}
                              toggle={() => dispatch(addressModelStatus(true))}
                            >
                              <ModalHeader>
                                <AddAddress />
                              </ModalHeader>
                            </Modal>

                            <Modal
                              size="md"
                              isOpen={editaddressmodel}
                              toggle={() =>
                                dispatch(editaddressModelStatus(true))
                              }
                            >
                              <ModalHeader>
                                <EditAddress addressobject={editableAddress} />
                              </ModalHeader>
                            </Modal>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BiSolidTimeFive
                    // className="lord-icon  text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>Delivery Time</h4>
                  </div>
                  <div className="checkout-detail">
                    <div className="row g-4">
                      <div className="col-xxl-6">
                        <div className="delivery-option">
                          <div className="delivery-category">
                            <div className="shipment-detail">
                              <div className="form-check custom-form-check hide-check-box">
                                <label
                                  className="form-check-label"
                                  htmlFor="standard"
                                  style={{
                                    fontSize: "20px",
                                    color: "#7e412e",
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                    textAlign: "left",
                                  }}
                                >
                                  {moment(expectDeliveryTime).format(
                                    "DD MMMM YYYY"
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                {/* <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaWallet
                     className="lord-icon  text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div> */}
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>
                      Wallet Amount :{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          color: "#0e947a",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {loginData.WalletBalance - usedWalletAmount}
                      </span>{" "}
                    </h4>

                    <div class="checkbox-wrapper-6">
                      <div>
                        <input
                          className="tgl tgl-light"
                          id="cb1-6"
                          type="checkbox"
                          value="walletstatus"
                          checked={walletUse === true}
                          onChange={(e) => {
                            statuschange(e);
                          }}
                        />
                        <label className="tgl-btn" htmlFor="cb1-6"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="checkout-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaShoppingCart
                    //  className="lord-icon BiSolidTimeFive text-success"
                    style={{ fontSize: "20px", color: "" }}
                  />
                </div>
                <div className="checkout-box">
                  <div className="checkout-title">
                    <h4>Payment Option</h4>
                  </div>
                  <div className="checkout-detail">
                    <div
                      className="accordion accordion-flush custom-accordion"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="flush-headingFour"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="cash"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="cash"
                                  checked={paymentType === "CashOnDelivery"}
                                  onChange={(e) => {
                                    dispatch(
                                      paymentTypeChange("CashOnDelivery")
                                    );
                                  }}
                                />
                                Cash On Delivery
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div className="custom-form-check form-check mb-0">
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                <input
                                  className="form-check-input mt-0"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="credit"
                                  checked={paymentType === "paytmPayment"}
                                  onChange={(e) => {
                                    dispatch(paymentTypeChange("paytmPayment"));
                                  }}
                                />
                                Pay Now
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div id="topay" className="accordion-item">
                        <div className="accordion-header" id="flush-headingOne">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                          >
                            <div
                              className="custom-form-check form-check mb-0"
                              onClick={showDrawer}
                            >
                              <div
                                className="d-flex"
                                style={{
                                  flexDirection: "column",
                                  padding: "10px",
                                  paddingLeft: "21px",
                                }}
                              >
                                <label
                                  className="form-check-label"
                                  htmlFor="credit"
                                  style={{ padding: "0" }}
                                >
                                  <input
                                    className="form-check-input mt-0"
                                    name="flexRadioDefault"
                                    id="credit"
                                    checked={paymentType === "paytmPayment"}
                                    onChange={(e) => {
                                      dispatch(
                                        paymentTypeChange("paytmPayment")
                                      );
                                    }}
                                  />
                                  To Pay
                                </label>
                                <span
                                  style={{ fontSize: "10px", color: "#898686" }}
                                >
                                  Incl. all taxes and charges
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div id="topay" onClick={showDrawer}>
                        <div
                          style={{
                            margin: "5px",
                            backgroundColor: "#fff",
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px",
                            width: "340px",
                            padding: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div
                              id="credit"
                              checked={paymentType === "paytmPayment"}
                              onChange={(e) => {
                                dispatch(paymentTypeChange("paytmPayment"));
                              }}
                              style={{
                                color: "#000",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                              }}
                            >
                              To Pay
                            </div>
                            <spanfontWeight
                              style={{ fontSize: "10px", color: "#898686" }}
                            >
                              Incl. all taxes and charges
                            </spanfontWeight>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60px",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h5
                              style={{ color: "#00ab66" }}
                              className="fw-bold"
                            >
                              ₹{netPayable - CouponDiscountAmount}
                            </h5>
                            <FaChevronRight />
                          </div>
                        </div>
                      </div>
                      {address.length > 0 ? (
                        <>
                          {btnLoading === true ? (
                            <>
                              <button
                                id="topay"
                                className="btn text-white btn-md w-100 mt-4 fw-bold"
                                style={{ background: "#b3b3b3" }}
                              >
                                Place Order
                              </button>

                              <div
                                id="topay"
                                className="d-flex justify-content-center"
                              >
                                <h4
                                  style={{
                                    fontWeight: "bold",
                                    color: "#36d7b7",
                                  }}
                                >
                                  Order is processing
                                </h4>
                                <BeatLoader
                                  color={"#36d7b7"}
                                  loading={btnLoading}
                                  size={13}
                                  className="mt-2 mx-2"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <button
                                id="topay"
                                className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                                onClick={
                                  paymentType === "CashOnDelivery"
                                    ? cashOnDeliveryHandleSubmit
                                    : payTmPayHandleSubmit
                                }
                              >
                                Place Order
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            id="topay"
                            className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                            onClick={() => dispatch(addressModelStatus(true))}
                          >
                            Add New Address
                          </button>
                        </>
                      )}

                      <div className="maindrawer">
                        <Space className="d-hidden">
                          <Radio.Group
                            value={placement}
                            className="d-none"
                            onChange={onChange}
                          >
                            <Radio value="bottom">bottom</Radio>
                          </Radio.Group>
                        </Space>
                        <Drawer
                          placement={placement}
                          width={"100%"}
                          onClose={onClose}
                          height={310}
                          open={open}
                        >
                          <RightSideCheckOut2 />
                        </Drawer>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          size="md"
          isOpen={addressmodel}
          toggle={() => dispatch(addressModelStatus(true))}
        >
          <ModalHeader>
            <AddAddress />
          </ModalHeader>
        </Modal>
      </div>
    </>
  );
};

export default LeftSideCheckout;
