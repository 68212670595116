import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { addtoCart, decrementCart } from "../../../redux/cart/CartSlice";
import axios from "axios";
import { Baseurl } from "../../../config/BaseUrl";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import Title_component from "../../../component/Title_component";
import { FaCartPlus, FaPlus, FaMinus } from "react-icons/fa6";
import { Rate } from "antd";
import SingleProduct from "../../../component/SingleProduct";

const OrganicVegetable = () => {
  const { OrganicVegetable, CartItems, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const dispatch = useDispatch();
  const [toChangeColor, setToChangeColor] = useState(false);
  const [hotProducts, setHotProducts] = useState([]);
  const [protest, setProtest] = useState(false);

  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };
  const bg = {
    overlay: {
      background: "#FFFF00",
    },
  };

  useEffect(() => {
    try {
      let prod = ProductTotal;

      let hotproducts = prod.map((product) => ({
        ...product,
        PackSizes: product.PackSizes.map((pack) => ({ ...pack })),
      }));

      CartItems.forEach((cartitem) => {
        let itemIndex = hotproducts.findIndex(
          (product) => String(product._id) === String(cartitem.ProductId)
        );
        if (itemIndex >= 0) {
          let packIndex = hotproducts[itemIndex].PackSizes.findIndex(
            (pack) => String(pack._id) === String(cartitem.packsizeId)
          );
          if (packIndex >= 0) {
            hotproducts[itemIndex].PackSizes[packIndex].CartQuantity =
              cartitem.Qty;
          }
        }
      });
      setHotProducts(hotproducts);
      const verifyProduct = hotproducts.find(
        (data) => data.Url === "red-capsicum--organic"
      );
      if (verifyProduct) {
        hotproducts = hotproducts.filter(
          (data) =>
            data.Url !== "red-capsicum--organic" &&
            data.Url !== "lettuce-romaine-green-organic"
        );
        hotproducts = [verifyProduct, ...hotproducts];
      }
      setHotProducts(hotproducts);
    } catch (error) {}
  }, [CartItems, ProductTotal]);

  const addtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = [...hotProducts];

      products[itemIndex].PackSizes[packIndex].CartQuantity += 1;

      setHotProducts([...products]);
      setProtest(!protest);
    } catch (error) {}

    const formData = {
      ProductId: hotPro._id,
      ProductName: hotPro.ItemName,
      CatName: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      ItemName: hotPro.ItemName,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      Description: hotPro.Description,
      ImgUrl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      Price: Number(hotPro.PackSizes[packIndex].SellingPrice),
      Qty: 1,
      TotalAmount: Number(hotPro.PackSizes[packIndex].SellingPrice) * 1,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      TotalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      Cashback: hotPro.Cashback,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      maximumQuantity: hotPro.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = hotProducts;
      products[itemIndex].PackSizes[packIndex].CartQuantity -= 1;

      setHotProducts(products);
    } catch (error) {}

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  return (
    <>
      {hotProducts.length > 0 ? (
        <>
          <section>
            <div className="container">
              <Title_component title="savira's luxury tea" />
              <div className="product-border border-row mt-1">
                <div className="slider-6_2 no-arrow hide_swipper_box_lap">
                  <Swiper
                    // draggable={true}
                    grabCursor={true}
                    navigation={false}
                    pagination={false}
                    mousewheel={false}
                    keyboard={true}
                    modules={[
                      Navigation,
                      Pagination,
                      Mousewheel,
                      Keyboard,
                      // Autoplay,
                    ]}
                    // loop={true}
                    // autoplay={{
                    //   delay: 1500,
                    //   disableOnInteraction: false,
                    //   pauseOnMouseEnter: true,
                    // }}
                    breakpoints={{
                      360: {
                        slidesPerView: 2.6,
                        spaceBetween: 5,
                      },
                      460: {
                        slidesPerView: 2.6,
                        spaceBetween: 5,
                      },
                      720: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                      },
                      1080: {
                        slidesPerView: 5.1,
                        spaceBetween: 4,
                      },
                    }}
                    className="mySwiper"
                  >
                    <>
                      {hotProducts &&
                        hotProducts.slice(0, 12).map((product, itemIndex) => (
                          <SwiperSlide key={product._id}>
                            <SingleProduct
                              itemIndex={itemIndex}
                              product={product}
                              addtocartPress={addtocartPress}
                              decrementtocartPress={decrementtocartPress}
                            />
                          </SwiperSlide>
                        ))}
                    </>
                  </Swiper>
                </div>
                <div className="hide_swipper_box_mob">
                  <div className="mobile_product_box">
                    {hotProducts &&
                      hotProducts.map((product, itemIndex) => (
                        <div className="mobile_product_display_box">
                          <SingleProduct
                            itemIndex={itemIndex}
                            product={product}
                            addtocartPress={addtocartPress}
                            decrementtocartPress={decrementtocartPress}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
    </>
  );
};

export default OrganicVegetable;
