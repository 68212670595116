import React, { useEffect, useState } from "react";
import HomeSlider from "./Home/HomeSlider";
import TrendingProducts from "./Home/TrendingProducts";
import OrganicVegetable from "./Home/subcategory/OrganicVegetable";
import OrganicFruits from "./Home/subcategory/OrganicFruits";
import AboutUs from "./Home/AboutUs";
import AdboutUsMobile from "./Home/AdboutUsMobile";

const Home = () => {

  return (
    <>
      <HomeSlider />
      <AboutUs />
      <OrganicVegetable />
      {/* <OrganicFruits />
      <TrendingProducts /> */}
      <AdboutUsMobile />

      <div style={{ marginBottom: "6vh" }}></div>
    </>
  );
};

export default Home;
