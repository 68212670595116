import { Rate } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { FaCartPlus, FaPlus, FaMinus } from "react-icons/fa6";

const SingleProduct = ({
  itemIndex,
  product,
  addtocartPress,
  decrementtocartPress,
}) => {
  return (
    <>
      <div className=" px-0 mt-1">
        <div className="product-box wow fadeIn">
          {product.PackSizes.map((pack, packIndex) =>
            pack.Pri === true ? (
              <>
                <Link to={`/product-info/${product.Url}`}>
                  <div className="product-image" style={{ cursor: "pointer" }}>
                    <a>
                      <img
                        src={pack.ImgUrlDesk}
                        className="img-fluid blur-up lazyload offertext"
                        alt
                      />
                      {pack.Mrp !== pack.SellingPrice && (
                        <>
                          <span id="lorel">{pack.Discount}% off</span>
                          <span id="lorel1">{pack.Discount}% off</span>
                        </>
                      )}
                    </a>
                  </div>
                </Link>

                <div className="product-detail paddtext">
                  <a>
                    <h6
                      className="name name-2 h-100"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {product.ItemName}
                    </h6>
                  </a>
                  <h6 className="sold weight text-content fw-normal">
                    {product.Brand}
                  </h6>
                  <h6
                    className="sold"
                    style={{
                      color: "#000",
                      fontFamily: "Poppins",
                    }}
                  >
                    ₹{pack.SellingPrice}
                    {pack.Mrp === pack.SellingPrice ? (
                      <> </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "#ccc",
                            fontSize: "12px",
                            textDecorationLine: "line-through",
                            paddingLeft: "1vh",
                          }}
                        >
                          ₹{pack.Mrp}
                        </span>
                      </>
                    )}
                  </h6>
                  <div className="riew_box_cd">
                    <Rate disabled defaultValue={5} className="custom-rate" />
                    <span className="review_text">122 reviews</span>
                  </div>
                  {pack.OutOfStack === true ? (
                    <>
                      <div className="add_to_button_container">
                        <span>Out Of Stock</span>
                        <FaCartPlus className="add_cart_ico" />
                      </div>
                    </>
                  ) : (
                    <>
                      {pack.CartQuantity > 0 ? (
                        <>
                          <div className="add_to_button_container">
                            <div
                              className="cart_btn_sm_box1"
                              onClick={(e) =>
                                decrementtocartPress(
                                  e,
                                  product,
                                  itemIndex,
                                  packIndex
                                )
                              }
                            >
                              <FaMinus className="add_cart_ico" />
                            </div>
                            <div className="cart_btn_sm_box">
                              <span>{pack.CartQuantity}</span>
                            </div>
                            <div
                              className="cart_btn_sm_box2"
                              onClick={(e) => {
                                if (pack.CartQuantity <= 9) {
                                  addtocartPress(
                                    e,
                                    product,
                                    itemIndex,
                                    packIndex
                                  );
                                }
                              }}
                            >
                              <FaPlus className="add_cart_ico" />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="add_to_button_container"
                            onClick={(e) =>
                              addtocartPress(e, product, itemIndex, packIndex)
                            }
                          >
                            <span>Add To Cart</span>
                            <FaCartPlus className="add_cart_ico" />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div key={pack._id}></div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default SingleProduct;
