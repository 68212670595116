import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const { isAuth } = useSelector((state) => state.Athentication);

  return (
    <>
      {/* Footer Section Start */}
      <div className="marginbootom"></div>
      <footer className="section-t-space mt-4">
        <div className="container-fluid-lg">
          {/* <div className="service-section">
            <div className="row g-3">
              <div className="col-12">
                <div className="service-contain">
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/product.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Every Fresh Products</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/delivery.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Free Delivery For Order Over $50</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Daily Mega Discounts</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Best Price On The Market</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="main-footer section-b-space section-t-space ">
            <div className="row g-md-4 g-3" style={{ marginTop: "30px" }}>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="footer-logo">
                  <div className="theme-logo">
                    <Link to="/">
                      <img
                        src="../assets/images/logo.png"
                        className="blur-up lazyload"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="footer-logo-contain">
                    <ul className="address">
                      <li
                        style={{
                          color: "#fff",
                        }}
                      >
                        <i data-feather="home" />
                        Sayva Foods Private Limited, No-120, 1st A Main,
                        Kasturinagar, Ngef East Layout, Bengaluru,
                        Karnataka-560043
                      </li>
                      <li
                        style={{
                          color: "#fff",
                        }}
                      >
                        <i data-feather="mail" />
                        <spam href="mailto: info@saviralife.com">
                          info@saviralife.com
                        </spam>
                      </li>
                      <li
                        style={{
                          color: "#fff",
                        }}
                      >
                        GSTIN : 29ABNCS4329M1ZK
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <div className="footer-title">
                  <h4>My Account</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/">
                            <spam className="ftext-content">
                              Profile &amp; Details
                            </spam>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/">
                            <spam className="ftext-content">
                              Profile &amp; Details
                            </spam>
                          </Link>
                        </li>
                      </>
                    )}
                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/">
                            <spam className="ftext-content">Order History</spam>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/">
                            <spam className="ftext-content">Order History</spam>
                          </Link>
                        </li>
                      </>
                    )}
                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/">
                            <spam className="ftext-content">
                              Address Manage
                            </spam>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/">
                            <spam className="ftext-content">
                              Address Manage
                            </spam>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-xl col-lg-2 col-sm-3">
                <div className="footer-title">
                  <h4>Information</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    <li>
                      <Link to="/returnefund">
                        <spam className="ftext-content">Returns</spam>
                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/faq">
                        <spam className="ftext-content">FAQ</spam>
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/privacy">
                        <spam className="ftext-content">Privacy Policy</spam>
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">
                        <spam className="ftext-content">
                          Terms & Conditions
                        </spam>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-sm-3">
                <div className="footer-title">
                  <h4>Who Are We?</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    <li>
                      <Link to="/about">
                        <spam className="ftext-content">About Us</spam>
                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/" className="ftext-content">
                        Career
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/contact-us">
                        <spam className="ftext-content">Contact Us</spam>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <spam className="ftext-content">Site Map</spam>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="footer-title">
                  <h4>Contact Us</h4>
                </div>
                <div className="footer-contact">
                  <ul>
                    <li>
                      <div className="footer-number">
                        <i data-feather="phone" />
                        <div className="contact-number">
                          <h5
                            style={{
                              color: "#fff",
                            }}
                          >
                            (+91) 7904 740 938
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="footer-number">
                        <i data-feather="mail" />
                        <div className="contact-number">
                          <div className="footer-title">
                            <h4>Email Address :</h4>
                          </div>
                          <h5
                            style={{
                              color: "#fff",
                            }}
                          >
                            <spam href="mailto: info@saviralife.com">
                              info@saviralife.com
                            </spam>
                          </h5>
                        </div>
                      </div>
                    </li>
                    {/* <li className="social-app">
                      <h5 className="mb-2 ftext-content">Download App :</h5>
                      <ul>
                        <li className="mb-0">
                          <spam
                            href="https://play.google.com/store/apps"
                            target="_blank"
                          >
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/images/playstore.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </spam>
                        </li>
                        <li className="mb-0">
                          <spam
                            href="https://www.apple.com/in/app-store/"
                            target="_blank"
                          >
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/images/appstore.svg"
                              className="blur-up lazyload"
                              alt
                            />
                          </spam>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-footer section-small-space">
            <div className="reserve">
              <h6 className="ftext-content">
                ©2024 Powered By Dexterous Technology
              </h6>
            </div>
            <div className="payment">
              <img
                src="../assets/images/payment/1.png"
                className="blur-up lazyload"
                alt
              />
            </div>
            <div className="social-link">
              <h6 className="ftext-content">Stay connected :</h6>
              <ul>
                <li>
                  <spam href="https://www.facebook.com/" target="_blank">
                    <i className="fa-brands fa-facebook-f" />
                  </spam>
                </li>
                <li>
                  <spam href="https://twitter.com/" target="_blank">
                    <i className="fa-brands fa-twitter" />
                  </spam>
                </li>
                <li>
                  <spam href="https://www.instagram.com/" target="_blank">
                    <i className="fa-brands fa-instagram" />
                  </spam>
                </li>
                <li>
                  <spam href="https://in.pinterest.com/" target="_blank">
                    <i className="fa-brands fa-pinterest-p" />
                  </spam>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Section End    */}
    </>
  );
};

export default Footer;
